(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('TrackingFactory', ['$http', '$timeout', 'Page', 'Profile', '$rootScope', 'events',
        function ($http, $timeout, Page, Profile, $rootScope, events) {
            var _eventQueue = [];
            var _timer = null;
            var _lastInstantTrackCheck = null;
            var _instantTrack = true;
            var _serverDifference = 0;
            var _hasFlushed = false;

            // run init
            initialize();

            $rootScope.$on(events.TRACKINGEVENT_NEW, (args, data) => {
                trackEvent(data.eventType, data.data);
            });

            $rootScope.$on(events.APP_UNHIDE, () => tryFlushEvents(false));
            $rootScope.$on(events.APP_HIDE, () => tryFlushEvents(true));

            return {
                trackEvent: trackEvent,
                trackPageView: trackPageView,
                trackAppHide: trackAppHide,
                trackAppUnhide: trackAppUnhide
            };

            // initialize factory
            function initialize() {
                // set a date for when there was last time a tracking done
                _lastInstantTrackCheck = new Date();

                $http.head('/track/ping').then(function (r) {
                    // read the server time header
                    try {
                        var dateHeader = r.headers('date');
                        var serverDate = new Date(dateHeader);
                        var clientDate = new Date();

                        // determine difference from client time
                        var difference = serverDate.getTime() - clientDate.getTime();
                        // save difference
                        _serverDifference = Math.round(difference / 1000);
                        // no longer require instant tracking
                        _instantTrack = false;
                    }
                    catch (err) {
                        console.error(err);
                    }
                }, function (err) {
                    // something went wrong, so we won't create dates
                    console.error(err);
                });
            }

            function trackPageView(url){
                var data = {
                    url: url
                };

                const isInstant = (url === '/'); // we always flush when we hit the frontpage
                trackEvent('Relesys.Tracking.Event.PageView', data, isInstant);
            }

            function trackAppHide(){
                trackEvent('Relesys.Tracking.Event.AppHide', null, true);
            }

            function trackAppUnhide(){
                trackEvent('Relesys.Tracking.Event.AppUnhide', null, true);
            }

            function trackEvent(eventType, data, instantTrack) {
                // create event
                var ev = {
                    type: eventType,
                    data: data
                };

                // add time to object as it will not always be instantly flushed
                // this is done using the server difference in seconds
                if (_serverDifference !== 0) {
                    ev.time = new Date(new Date().getTime() + (_serverDifference * 1000));
                } else {
                    ev.time = new Date();
                }

                // add event to queue
                _eventQueue.push(ev);

                tryFlushEvents(_instantTrack || instantTrack);                
            }

            function startFlushTimer() {
                // starts a timer with a timeout of 15 seconds
                _timer = $timeout(flushEvents, 15000);
            }

            function tryFlushEvents(isInstant) {
                // start timer if not already running
                if (_hasFlushed === false || isInstant) {
                    // we flush the first time straight away to ensure we've done the first tracking
                    flushEvents(isInstant);
                } else if (_timer === null) {
                    // starts a timer
                    startFlushTimer();
                }
            }

            function flushEvents(isInstant) {
                if (!_.isObjectLike(Page.getSettings())) {
                    return;
                }

                if (!_.isObjectLike(Profile.getProfile())) {
                    return;
                }

                if (!(Profile.getProfile())) {
                    return;
                }

                if (_eventQueue.length === 0) {
                    return;
                }

                // Don't flush if app is not visible - Only if instant as that happens through other events which ensure the app is visible
                // We stop the flush as app visibility change will flush events all over if needed
                if (!isInstant && Page.isAppVisible() == false) {
                    if(_timer != null){
                        $timeout.cancel(_timer)
                    }
                    return;
                }

                // instantly stop / reset the timer
                // and take the events out and reset that array as well
                var eventsToSend = _.cloneDeep(_eventQueue);
                _timer = null;
                _eventQueue = [];

                // if instantTrack = TRUE, there needs to be another check against the server
                if (_instantTrack) {
                    // should it have been more than 5 minutes since last check, another one is done
                    const timeDifference = 5 * 60 * 1000;
                    if (_lastInstantTrackCheck === null
                        || (_lastInstantTrackCheck.getTime() + timeDifference) < new Date().getTime()) {
                        // run a new initialization
                        initialize();
                    }
                }

                // send events
                $http.post('/track/event', eventsToSend).then(function () {
                    // it went well, so nothing is done
                    // apart from marking as having been flushed before
                    _hasFlushed = true;
                }, function (err) {
                    // it failed, so they are added back in the queue
                    _.each(eventsToSend, function (o) {
                        _eventQueue.push(o);
                    });
                    // log error
                    console.error(err);
                    // ensure we won't try again immidiately
                    _hasFlushed = true;
                    
                    if (err.status && err.status == 404){
                        return;
                    }

                    // start a new timer for flushing
                    startFlushTimer();
                });
            }
        }
    ]);
})();