(function () {
    'use strict';

    angular
        .module('App')
        .factory('BookmarkService', ['$http', '$q', 'Page', BookmarkService]);

    function BookmarkService($http, $q, Page) {
        let isDropdownVisible = false;

        return {
            createContentBookmark: createContentBookmark,
            createSocialBookmark: createSocialBookmark,
            deleteBookmark: deleteBookmark,
            getBookmarks: getBookmarks,
            isEnabled: isEnabled,
            toggleDropdownVisibility: toggleDropdownVisibility,
            getDropdownVisibility: () => isDropdownVisible,
            getBookmarkLink: getBookmarkLink
        };

        function getBookmarks() {
            var d = $q.defer();

                $http.get('/Bookmark/GetList')
                    .then((response) => {
                        d.resolve(response.data);
                    })
                    .catch(() => {
                        d.resolve(false);
                    })

            return d.promise;
        }

        function getBookmarkLink(bookmarkId) {
            const d = $q.defer();
        
            $http.get(`/Bookmark/GetAppLink/${bookmarkId}`)
                .then((response) => {
                    d.resolve(response.data);
                })
                .catch(() => {
                    d.resolve(false);
                });
        
            return d.promise;
        }

        function createContentBookmark(contentToken) {
            return createBookmarkInternal('/BookmarkContent/Create', contentToken);
        }

        function createSocialBookmark(socialPostToken) {
            return createBookmarkInternal('/BookmarkSocial/Create', socialPostToken);
        }

        function createBookmarkInternal(url, token) {
            var d = $q.defer();
            $http.post(url, { token: token })
                .success((response)  => d.resolve(response.bookmarkId))
                .error(() => d.resolve(false));

            return d.promise;
        }

        function deleteBookmark(bookmarkId) {
            var d = $q.defer();

            $http.post('/Bookmark/Delete/' + bookmarkId)
                .success((response) => d.resolve(response))
                .error(() => d.resolve(false));

            return d.promise;
        }

        function isEnabled() {
            var settings = Page.getSettings();
            if (settings && settings.Base) {
                return settings.Base.BookmarkingEnabled;
            }
            return false;
        }

        function toggleDropdownVisibility(visible) {
            if(_.isBoolean(visible)) {
                isDropdownVisible = visible;
            }
            else {
                isDropdownVisible = !isDropdownVisible;
            }
        }
    }
})();